import React from 'react';
import { Box, Center, Heading, Text, Image, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import WithAnimation from '@components/Common/WithAnimation';

import { BG_IG_FILTER } from '@constants/assets';
import { HEADING_STYLES } from '@constants/colors';
import { ID_INSTAGRAM_SECTION } from '@constants/identifier';
import { IG_FILTER } from '@constants/';

import txt from './locales';
import useLang from '@hooks/useLang';

function InstagramFilter() {
  const lang = useLang();

  const handleClickFilterIG = () => {
    window.open(IG_FILTER, '_blank');
  };

  return (
    <Box position="relative" bgColor="bgPrimary" padding="12px 0px 32px 0" name={ID_INSTAGRAM_SECTION}>
      <Box height="84px" bgColor="bgSecondary" width="100%" transform="translateY(380%)" />
      <Box padding="0 52px" marginTop="-82px">
        <Center>
          <WithAnimation>
            <Heading {...HEADING_STYLES}>FILTER</Heading>
          </WithAnimation>
        </Center>
        <WithAnimation>
          <Text fontFamily="serif" color="mainColorText" align="center" margin="16px 0">
            {txt.subTitle[lang]}
          </Text>
        </WithAnimation>
        
        <WithAnimation>
          <Image zIndex="3" src={BG_IG_FILTER} borderRadius='md' width="100%" />
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Button
              leftIcon={<FaInstagram />}
              size="sm"
              fontSize='md'
              fontStyle='italic'
              fontWeight="bold"
              colorScheme="blackAlpha"
              bgColor="bgAlternative"
              color="mainColorTextLight"
              onClick={handleClickFilterIG}
              marginTop='24px'
            >
              {txt.textButton[lang]}
            </Button>
          </Center>
        </WithAnimation>
      </Box>
      
    </Box>
  );
}

export default InstagramFilter;
