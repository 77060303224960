import {
  ENABLE_QR_INVITATION,
  ENABLE_COVID_PROTOCOL,
  ENABLE_RSVP,
  ENABLE_GIFT_CARD,
  ENABLE_LIVE_STREAMING,
  ENABLE_INSTAGRAM_FILTER,
} from '@/constants/feature-flags';
import * as ID from '@/constants/identifier';
import useLang from '@/hooks/useLang';

import txt from '../locales';
import { IS_BOY_FIRST } from '@constants/index';

/**
 * Documentation
 * name -> will be show as menu title
 * value -> identifier for unique section. Will scroll to value element
 * invitationOnly -> only show menu if user in invitation mode
 * link -> will direct to new page if link available
 * offset -> used for add extra space when scroll
 */
export default function useMenuData() {
  const lang = useLang();

  const menuData = [
    {
      name: txt.accessCard[lang],
      value: ID.ID_QRCODE,
      invitationOnly: true,
      link: '',
      offset: -16,
      show: ENABLE_QR_INVITATION,
    },
    {
      name: 'Our Story',
      value: ID.ID_LOVESTORY_SECTION,
      invitationOnly: false,
      link: '',
      offset: -16,
      show: false,
    },
    {
      name: IS_BOY_FIRST ? 'Groom & Bride' : txt.bride[lang],
      value: ID.ID_COUPLE_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: true,
    },
    {
      name: txt.details[lang],
      value: ID.ID_DETAIL_SECTION,
      invitationOnly: true,
      link: '',
      offset: 0,
      show: true,
    },
    {
      name: 'Countdown',
      value: ID.ID_COUNTDOWN_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: false,
    },
    {
      name: txt.rsvp[lang],
      value: ID.ID_RSVP_SECTION,
      invitationOnly: true,
      link: '',
      offset: 0,
      show: ENABLE_RSVP,
    },
    {
      name: 'Gallery',
      value: ID.ID_GALLERY_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: false,
    },
    {
      name: txt.covidProtocol[lang],
      value: ID.ID_COVID_SECTION,
      invitationOnly: true,
      link: '',
      offset: -160,
      show: ENABLE_COVID_PROTOCOL && false,
    },
    {
      name: txt.live[lang],
      value: ID.ID_YOUTUBE_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_LIVE_STREAMING && false,
    },
    {
      name: txt.gift[lang],
      value: ID.ID_GIFT_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_GIFT_CARD,
    },
    {
      name: 'Wish',
      value: ID.ID_WISH_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_GIFT_CARD && false,
    },
    {
      name: 'Filter',
      value: ID.ID_INSTAGRAM_SECTION,
      invitationOnly: false,
      link: '',
      offset: 0,
      show: ENABLE_INSTAGRAM_FILTER && false,
    },
  ];

  return menuData.filter((i) => i.show);
}
