const img = '';

export default [
  {
    img: img,
    title: 'May 2014',
    description: `First time met as classmate at junior high school, we became friends.`,
  },
  {
    img: img,
    title: 'January 2016',
    description: `We started chatting, getting closer and finally dating!`,
  },
  {
    img: img,
    title: 'October 2017',
    description: `As Daniel pursued his study in Australia, we officially starting our Long-Distance Relationship.`,
  },
  {
    img: img,
    title: 'July 2019',
    description: `First trip together, Cindy and family visited Daniel in Australia. This led us to an unforgettable moment.`,
  },
  {
    img: img,
    title: 'May 2021',
    description: `After 3 years. Our Long-Distance Relationship phase comes to an end.`,
  },
  {
    img: img,
    title: 'March 2023',
    description: `Daniel proposed Cindy to marry him, for a lifetime of joy and adventure together. Cheers!`,
  },
  {
    img: img,
    title: 'May 2023',
    description: `Tingjing day.`,
  },
  {
    img: img,
    title: 'September 2023',
    description: `Sangjit day.`,
  },
  {
    img: img,
    title: 'January 2024',
    description: `THE WEDDING DAY. And now we invite you to celebrate next chapter of our story.`,
  },
];