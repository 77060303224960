import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function CountItem({ number, text }) {
  return (
    <Box p={3}>
      <Box>
        <Text
          textAlign="center"
          fontSize="3xl"
          fontFamily="heading"
          color="mainColorText"
          letterSpacing="2px"
          margin="0 8px"
        >
          {number}
        </Text>
      </Box>
      <Box>
        <Text textAlign="center" marginTop="0" fontSize="18px" color="mainColorText">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
